$colors: (
  "white": #ffffff,
  "black": #000000,
  "darkblue": #021638,
  "blue": #4864E1,
  "softblue": #F9F9FB,
  "transparent": transparent,
  "red": #FF4747,
  "gray-1": #A2A1A7,
  "gray-2": #5E5D65,
);

// Default Font Size
$base-font-size: 16px;

// Responsive Breakpoint
$br-xxs: 375px;
$br-xx: 425px;
$br-xs: 480px;
$br-mm: 512px;
$br-sm: 576px;
$br-md: 768px;
$br-lg: 992px;
$br-xl: 1140px;
$br-xxl: 1200px;
