@import "../abstracts/variables";
@import "../abstracts/functions";
@import "../abstracts/keyframes";

.section {
}

.field {
  &__input {
    border: 1px solid #e9ebed;
    background-color: #f9f9f9;
    padding: 1rem;
    display: flex;
    align-items: center;
    height: 60px;
    border-radius: 32px;

    &.min-250 {
      min-width: 250px;
    }

    &.w-sm-100 {
      @media screen and(max-width: $br-sm) {
        width: 100%;
        min-width: auto;
      }
    }

    &.search {
      width: 327px;
    }

    &.round__16 {
      border-radius: 16px;
    }

    &.verification {
      background-color: transparent;
      border: none;
      input {
        width: 55px;
        height: 55px;
        border-radius: 12px;
        box-shadow: none;
        background: #fefefe;
        border: 1px solid #e9ebed;
        border-radius: 24px;

        @media screen and(max-width: $br-mm) {
          width: 50px;
          height: 55px;
        }

        &:valid {
          border: 1px solid #e9ebed;
          border-radius: 24px;
          box-shadow: none;
        }

        &:hover {
          border: 1px solid color("blue");
          border-radius: 24px;
          box-shadow: none;
        }
        &:focus {
          border: 1px solid color("blue");
          border-radius: 24px;
          box-shadow: none;
        }
        &:active {
          border: 1px solid color("blue");
          border-radius: 24px;
          box-shadow: none;
        }
      }
    }

    input {
      background-color: transparent;
      border: none;
      outline-style: none;
      box-shadow: none;
      padding-left: 0;
      padding-right: 0;
      width: 100%;

      &[type="date"]::-webkit-inner-spin-button,
      &[type="date"]::-webkit-calendar-picker-indicator {
        display: none;
        -webkit-appearance: none;
      }

      &:hover {
        background-color: transparent;
        border: none;
        outline-style: none;
        box-shadow: none;
      }
      &:focus {
        background-color: transparent;
        border: none;
        outline-style: none;
        box-shadow: none;
      }
      &:active {
        background-color: transparent;
        border: none;
        outline-style: none;
        box-shadow: none;
      }
    }

    &.textarea {
      height: auto;
    }

    textarea {
      background-color: transparent;
      border: none;
      outline-style: none;
      box-shadow: none;
      padding-left: 0;
      padding-right: 0;
      width: 100%;

      &:hover {
        background-color: transparent;
        border: none;
        outline-style: none;
        box-shadow: none;
      }
      &:focus {
        background-color: transparent;
        border: none;
        outline-style: none;
        box-shadow: none;
      }
      &:active {
        background-color: transparent;
        border: none;
        outline-style: none;
        box-shadow: none;
      }
    }
  }
}

.wrapper {
  &__cliet-wrap {
    padding: 4rem;
    border-radius: 48px;

    @media screen and(max-width: $br-md) {
      padding-bottom: 0;
    }
    @media screen and(max-width: $br-sm) {
      padding: 2rem;
      padding-bottom: 0;
    }
  }
  &__drop-wrap {
    .center {
      position: absolute;
      width: 100%;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      .image__center {
        max-width: 100px;
        max-height: 100px;
        object-fit: contain;
      }
    }
  }
  &__drop-item {
    max-width: 100% !important;
    width: 100%;
    height: 200px !important;
    border-radius: 24px !important;
    min-width: 100% !important;
    svg {
      display: none;
    }

    > div {
      display: none;
    }
  }
  &__form-input {
    background-color: white;
    border: none;
    border-radius: 50px;
    padding: 4px 20px;
    border: 1px solid #e9ebed;
    height: 52px;
    outline-style: none;
    box-shadow: none;

    &.textarea {
      border-radius: 24px;
      padding: 1.5rem;
    }

    &:focus {
      outline-style: none;
      box-shadow: none;
      border: 1px solid #e9ebed;
    }
    &:hover {
      outline-style: none;
      box-shadow: none;
      border: 1px solid #e9ebed;
    }
    &:active {
      outline-style: none;
      box-shadow: none;
      border: 1px solid #e9ebed;
    }
  }
  &__list-step-form {
    .item {
      margin-bottom: 20px;

      &::before {
        content: "";
        position: absolute;
        width: 1px;
        height: 100%;
        left: 16px;
        top: 0;
        background-color: #e9ebed;
        z-index: -1;
      }

      &:last-child {
        margin-bottom: 0;
      }
      &.active {
        .round {
          background-color: color("blue");
          border: 1px solid color("blue");
          color: white;
        }

        span {
          color: color("black");
        }
      }
      .round {
        background-color: #f9f9fb;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        border: 1px solid #e9ebed;
        color: #c7c7cc;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      span {
        color: #c7c7cc;
      }
    }
  }
  &__card-price {
    border-radius: 16px;
    background-color: white;
    padding: 2rem;

    .list__feature {
      list-style: none;
      padding: 0;
      margin: 0;
      li {
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  &__label-premium {
    padding: 4px 16px;
    border-radius: 20px;
    background: rgba(255, 125, 87, 0.08);
    color: #ff7d57;
  }
  &__icon {
    &-56 {
      width: 56px;
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #e9ebed;
      border-radius: 50%;

      img {
        object-fit: contain;
      }
    }
    &-40 {
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #e9ebed;
      border-radius: 50%;

      img {
        object-fit: contain;
      }
    }
  }
  &___detail-jobs {
    width: 100%;
    overflow: hidden;
    border-radius: 16px;
    background-color: white;

    &.active {
      border: 1px solid color("blue");
    }

    .bg__cover {
      object-fit: cover;
      height: 124px;
      width: 100%;
    }

    .detail {
      padding: 1rem;
      padding-top: 0;
      margin-top: -2.5rem;
      position: relative;
      z-index: 2;

      .profile {
        width: 72px;
        height: 72px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid white;
        background-color: #eef1fb;
        border-radius: 50%;
      }
    }
  }
  &__filter-icon {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #e9ebed;
    border-radius: 50%;
  }
  &__btn-filter {
    background-color: transparent;
    padding: 10px 30px;
    border-radius: 50px;
    border: 1px solid #e9ebed;
  }
  &__filter-list {
    &.active {
      .head {
        padding-bottom: 0.35rem;

        .arrow {
          transform: rotate(-180deg);
        }
      }

      .list {
        padding-top: 0.35rem;
        max-height: 50vh;
        visibility: visible;
        opacity: 1;
      }
    }

    .head {
      padding-bottom: 0;

      .arrow {
        transition: 0.5s;
      }
    }

    .list {
      max-height: 0;
      visibility: hidden;
      opacity: 0;
      transition: 0.5s;

      .items {
        margin-bottom: 0.7rem;
      }
    }
  }
  &__number-filter {
    background: #4864e114;
    padding: 2px 16px;
    border-radius: 20px;
  }
  &__dot {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    display: inline-block;
    background-color: color("gray-1");
  }
  &__list-data {
    margin: 0 -8px;

    &.favorite {
      margin: 0 -15px;
      > div {
        padding: 0 15px;
        &::after {
          right: 0;
        }
      }
    }
    > div {
      padding: 0 8px;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        height: 20px;
        width: 1px;
        background-color: #e9ebed;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }

      &:last-child {
        &::after {
          display: none;
        }
      }
    }
  }
  &__card-jobs {
    padding: 1.5rem;
    background-color: white;
    border-radius: 16px;
    display: inline-block;
    width: 100%;
    height: 100%;

    &.active {
      border: 1px solid color("blue");
    }

    .head {
      .logo {
        width: 56px;
        height: 56px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f9f9fb;
      }
    }
  }
  &__location {
    border: 1px solid #e9ebed;
    padding: 6px 10px;
    border-radius: 50px;
    input {
      height: 40px;
      background: transparent;
      border: none;
      outline-style: none;
      box-shadow: none;
      &:hover {
        background: transparent;
        border: none;
        outline-style: none;
        box-shadow: none;
      }
      &:focus {
        background: transparent;
        border: none;
        outline-style: none;
        box-shadow: none;
      }
      &:active {
        background: transparent;
        border: none;
        outline-style: none;
        box-shadow: none;
      }
    }
  }
  &__search {
    border: 1px solid #e9ebed;
    padding: 6px 10px;
    border-radius: 50px;
    input {
      height: 40px;
      background: transparent;
      border: none;
      outline-style: none;
      box-shadow: none;
      &:hover {
        background: transparent;
        border: none;
        outline-style: none;
        box-shadow: none;
      }
      &:focus {
        background: transparent;
        border: none;
        outline-style: none;
        box-shadow: none;
      }
      &:active {
        background: transparent;
        border: none;
        outline-style: none;
        box-shadow: none;
      }
    }
  }
  &__content-menu {
    padding: 2.5rem 0;
  }
  &__skill-tool {
    margin: 0 -5px;
    img {
      margin: 0 5px;
      margin-bottom: 10px;
      width: 32px;
      height: 32px;
    }
  }
  &__skill-list {
    margin: 0 -5px;
    .items {
      padding: 6px 16px;
      border-radius: 20px;
      background-color: #4864e10a;
      margin: 0 5px;
      margin-bottom: 10px;
      transition: 0.5s;

      &:hover {
        background-color: color("blue");
        color: white !important;
      }
    }
  }
  &__side-dashboad {
    width: 100%;
    height: 100%;
    border-left: 1px solid #e9ebed;
    border-right: 1px solid #e9ebed;
    padding: 2.5rem 1.5rem;
  }
  &__notif-menu {
    margin: 0 -10px;
    @media screen and(max-width: $br-xxs) {
      margin: 0 -5px;
    }
    .item {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #e9ebed;
      margin: 0 10px;
      
      @media screen and(max-width: $br-xxs) {
        width: 40px;
        height: 40px;
        margin: 0 5px;
      }

      &.user {
        border: 1px solid white;
        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          object-fit: cover;
        }
      }
    }
  }
  &__input-code {
    @media screen and(max-width: $br-mm) {
      margin: 0 -10px;

      .field__input {
        padding: 0 10px;
      }
    }
  }
  &__modal-connect {
    display: block !important;
    opacity: 0;
    visibility: hidden;
    transition: 0.5s;

    &.post {
      .modal-dialog {
        max-width: 550px !important;
      }
    }

    .modal-content {
      padding: 2rem 1rem;

      .close__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        background: #f9f9f9;
        border-radius: 50%;
        position: absolute;
        left: -20px;
        top: -20px;
      }
    }

    &.show {
      opacity: 1;
      visibility: visible;
      transition: 0.5s;
    }

    .content__modal-listing {
      .img {
        width: 120px;
        height: 120px;
        object-fit: cover;
        border-radius: 16px;
        @media screen and(max-width: $br-xx) {
          width: 100px;
          height: 100px;
        }
      }

      .wrap {
        background: #f9f9f9;
        border-radius: 12px;
        padding: 8px 12px;
      }
    }

    .modal-dialog {
      max-width: 350px !important;
    }
  }
  &__form-auth {
    .content {
      padding: 2rem 4rem;
    }

    .content__center {
      height: 100vh;
    }
  }
  &__checkbox {
    &.filter {
    }
    input {
      opacity: 0;
      visibility: hidden;
      position: absolute;
      z-index: -2;

      &:checked {
        ~ .circle {
          background-color: color("blue");
          .check {
            opacity: 1;
          }
        }
      }
    }

    .circle {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background-color: white;
      position: relative;
      border: 1px solid #e5e5e7;

      &.box {
        border-radius: 4px;
      }

      .check {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        transition: 0.5s;
        opacity: 0;
      }
    }
  }
  &__input-field {
    background: white;
    border: 1px solid #e9ebed;
    outline-style: none;
    box-shadow: none;
    border-radius: 24px;
    height: 52px;
    padding: 0 20px;

    &.sub {
      background-color: rgba(255, 255, 255, 0.12);
      border: none;

      &:hover {
        background-color: rgba(255, 255, 255, 0.12);
        border: none;
        outline-style: none;
        box-shadow: none;
      }
      &:focus {
        background-color: rgba(255, 255, 255, 0.12);
        border: none;
        outline-style: none;
        box-shadow: none;
      }
      &:active {
        background-color: rgba(255, 255, 255, 0.12);
        border: none;
        outline-style: none;
        box-shadow: none;
      }
    }

    ~ .icon-hide {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 5%;
    }

    &:hover {
      background: white;
      border: 1px solid #e9ebed;
      outline-style: none;
      box-shadow: none;
    }
    &:focus {
      background: white;
      border: 1px solid #e9ebed;
      outline-style: none;
      box-shadow: none;
    }
    &:active {
      background: white;
      border: 1px solid #e9ebed;
      outline-style: none;
      box-shadow: none;
    }
  }
  &__line-auth {
    .line {
      position: absolute;
      width: 100%;
      height: 1px;
      background-color: #e9ebed;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }

    h5 {
      padding: 5px 15px;
      background-color: color("softblue");
    }
  }
  &__wrap-sign {
    padding: 4rem;
  }
  &__select-profile {
    padding: 2rem 1.5rem;
    border: 1px solid #e9ebed;
    border-radius: 24px;
  }
  &__list-number {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      margin-bottom: 20px;

      a {
        &.active {
          .number {
            border: 1px solid color("blue");
            background-color: color("blue");
            color: white;
          }

          p {
            color: color("blue") !important;
          }
        }
      }

      a {
        display: inline-block;
        .number {
          width: 32px;
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid #e9ebed;
          border-radius: 50%;
        }
      }
    }
  }
  &__community-activity {
    &.nested {
      > .img {
        &::before {
          content: "";
          position: absolute;
          width: 1px;
          height: 100%;
          border-left: 1px dashed #c7c7cc;
          border-spacing: 2px;
          left: 50%;
          transform: translateX(-50%);
          z-index: -1;
        }
      }

      &.left {
        > .img {
          &::after {
            content: "";
            position: absolute;
            width: 1px;
            height: 100%;
            top: 24px;
            left: -2.6rem;
            border-left: 3px solid #f9f9fb;
            z-index: 2;
          }
          &::before {
            width: 5rem;
            height: 1px;
            transform: initial;
            border-top: 1px dashed #c7c7cc;
            border-left: none;
            left: -2.5rem;
            top: 24px;
          }
        }
      }
    }
    div {
      .user {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        border: 1.5px solid #ffffff;
        object-fit: cover;
      }
    }
  }
  &__accordion-info {
    .head {
      cursor: pointer;
      padding-bottom: 0;
      transition: 0.5s;

      .arrow {
        transition: 0.5s;
      }
    }
    .desc {
      padding-top: 0;
      transition: 0.5s;
      max-height: 0;
      opacity: 0;
      visibility: hidden;
    }

    &.active {
      .head {
        padding-bottom: 0.5rem;
        .arrow {
          transform: rotate(-180deg);
          transform-origin: center;
        }
      }
      .desc {
        max-height: 50vh;
        padding-top: 0.5rem;
        opacity: 1;
        visibility: visible;
      }
    }
  }
  &__field-input-contact {
    width: 100%;
    border-radius: 50px;
    padding: 0 18px;
    height: 52px;
    border: 1px solid #e9ebed;
    outline-style: none;
    box-shadow: none;

    &.textarea {
      padding: 1rem;
      border-radius: 24px;
      height: auto;
    }

    &:hover {
      border: 1px solid #e9ebed;
      outline-style: none;
      box-shadow: none;
    }
    &:focus {
      border: 1px solid #e9ebed;
      outline-style: none;
      box-shadow: none;
    }
    &:active {
      border: 1px solid #e9ebed;
      outline-style: none;
      box-shadow: none;
    }
  }
  &__icon-info {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border: 1px solid #bdc7f4;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__wrap-info {
    background-color: white;
    border-radius: 24px;
    padding: 2rem;
  }
  &__left-height {
    .line {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 4px;
      height: 100%;
      background-color: color("blue");
    }
  }
  &__wrap-line {
    padding: 6px 16px;
    border-radius: 50px;
    border: 1px solid #e9ebed;
  }
  &__dot-list {
    display: inline-block;
    width: 6px;
    height: 6px;
    background-color: color("blue");
    border-radius: 50%;
  }
  &__pagination {
    svg {
      path {
        transition: 0.5s;
        stroke: #cbd2f6;
      }

      &:hover {
        path {
          stroke: black;
        }
      }
    }

    .list {
      display: flex;
      align-items: center;
      margin: 0 -2px;

      .dot {
        margin: 0 2px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #cbd2f6;

        &.active {
          width: 16px;
          border-radius: 24px;
          background-color: #4864e1;
        }
      }
    }
  }
  &__card-article {
    .cover {
      img {
        width: 100%;
        height: 240px;
        object-fit: cover;
        border-radius: 16px 16px 0 0;
      }

      .tag {
        padding: 4px 12px;
        border-radius: 50px;
        background: #ffffff3d;
        position: absolute;
        right: 15px;
        top: 15px;
      }
    }

    .desc {
      padding: 1rem;
      padding-top: 0;
    }
  }
  &__number-wrap {
    margin: 0 -20px;
    .wrapper__card-number {
      margin: 0 20px;
    }
  }
  &__card-number {
    background-color: white;
    width: 186px;
    height: 176px;
    backdrop-filter: blur(10px);
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__brand {
    margin: 0 -40px;
    img {
      margin: 0 40px;
    }

    @media screen and(max-width: 1024px) {
      margin: 0 -20px;
      img {
        margin: 0 20px;
        object-fit: contain;
        width: 10rem;
      }
    }
    @media screen and(max-width: $br-sm) {
      img {
        margin-bottom: 1.5rem;
      }
    }
  }
  &__photo-grid {
    .item {
      width: calc(100% / 2);
      max-height: 557px;

      &.img {
        &-1 {
          margin-left: 1rem;
          height: 100%;
          border-radius: 24px;
          object-fit: cover;
        }
      }

      &.wrap-1 {
        img {
          width: 100%;
          object-fit: cover;
          height: calc((557px - 1rem) / 2);
          border-radius: 24px;

          @media screen and(max-width: $br-sm) {
            height: calc((437px - 1rem) / 2);
          }
          @media screen and(max-width: $br-xx) {
            height: calc((315px - 1rem) / 2);
          }
          @media screen and(max-width: $br-xxs) {
            height: calc((275px - 1rem) / 2);
          }

          &:first-child {
            margin-bottom: 1rem;
          }
        }
      }
    }

    &.blog {
      .bg {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: #0000008f;
      }
      .tag {
        padding: 6px 16px;
        border-radius: 24px;
        background: rgba(255, 255, 255, 0.24);
        backdrop-filter: blur(10px);
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        @media screen and(max-width: $br-xs) {
          width: 70%;
          text-align: center;
        }
      }

      .item {
        width: calc(100% / 2);
        margin-right: 1rem;
        border-radius: 24px;
        overflow: hidden;

        .wrap-1 {
          height: calc((438px - 1rem) / 2);
          border-radius: 24px;
          overflow: hidden;

          @media screen and(max-width: $br-xs) {
            height: calc((300px - 1rem) / 2);
          }
          &:first-child {
            margin-bottom: 1rem;
          }
        }

        &.first {
          max-height: 438px;
          @media screen and(max-width: $br-xs) {
            max-height: 300px;
          }
        }

        .img {
          &-1 {
            border-radius: 24px;
            max-height: 438px;
            height: 100%;
            width: 100%;
            object-fit: cover;
            margin-right: 0;
            margin-left: 0;

            @media screen and(max-width: $br-xs) {
              max-height: 300px;
            }
          }
        }

        &.wrap-1 {
          img {
            height: calc((438px - 1rem) / 2);
            margin-bottom: 0;

            @media screen and(max-width: $br-xs) {
              height: calc((300px - 1rem) / 2);
            }
          }
        }
      }
    }
  }
  &__card-about {
    padding: 2rem;
    border-radius: 24px;
  }
  &__sosmed {
    margin: 0 -5px;
    &.center {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      @media screen and(max-width: $br-md) {
        transform: initial;
        position: initial;
      }
    }
    a {
      margin: 0 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border: 1px solid rgba(255, 255, 255, 0.12);
      border-radius: 50%;
    }
  }
  &__input-field-contact {
    border-radius: 50px;
    border: none;
    background: #ffffff1f;
    padding-left: 25px;
    padding-right: 25px;
    box-shadow: none;
    outline-style: none;
    height: 60px;
  }
  &__menu-footer {
    max-width: 0 -30px;
    @media screen and(max-width: $br-sm) {
      margin: 0 -30px;
    }
    a {
      display: inline-block;
      margin: 0 30px;
      margin-bottom: 1.5rem;
    }
  }
  &__arrow-width {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -2.4rem;
    z-index: 2;
    svg {
      &:nth-child(1) {
        margin-right: 4rem;
      }

      path {
        transition: 0.5s;
        stroke: #cbd2f6;
      }

      &:hover {
        path {
          stroke: black;
        }
      }
    }
  }
  &__slider-testimonial {
    margin: 0 -15px;
    .items {
      padding: 0 15px;
    }

    .slick-dots li {
      width: 8px;
      height: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0;
    }

    .slick-dots {
      // position: absolute !important;
      // width: 100% !important;
      // display: flex !important;
      // justify-content: center;
      // margin: 0;
      // padding: 1rem 0;
      // bottom: -3.5rem;
      // list-style-type: none;
    }

    .slick-dots li {
      margin: 0 0.25rem;
    }

    .slick-dots button {
      display: block;
      width: 1rem;
      height: 1rem;
      padding: 0;
      border: none;
      border-radius: 50px;
      background-color: #cbd2f6;
      text-indent: -9999px;
      height: 8px;
    }

    .slick-dots li.slick-active button {
      background-color: color("blue");
      width: 16px;
    }
    .slick-dots li.slick-active {
      width: 16px;
    }
  }
  &__card-testi {
    padding: 1.5rem 1rem;
    border-radius: 24px;
    border: 1px solid #cbd2f6;

    .user {
      > img {
        width: 56px;
        height: 56px;
        object-fit: cover;
        border-radius: 50%;
        border: 1.5px solid white;
      }
    }
  }
  &__arrow-icon {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: white;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 50%;
    transform: translateY(-50%);
    right: -0.5rem;
    z-index: 2;
    box-shadow: 12px 12px 24px 0px #0216383d;
  }
  &__slider-work {
    margin: 0 -15px;
    .items {
      padding: 0 15px;
    }

    .slick-dots li {
      width: 8px;
      height: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0;
    }

    .slick-dots {
      display: flex !important;
      justify-content: flex-start;
      margin: 0;
      padding: 1rem 0;
      list-style-type: none;
      padding-left: 2rem;
      top: 8.6rem;

      @media screen and(max-width: $br-md) {
        top: 6.6rem;
      }
      @media screen and(max-width: $br-sm) {
        top: 7.6rem;
      }
    }

    .slick-dots li {
      margin: 0 0.25rem;
    }

    .slick-dots button {
      display: block;
      width: 1rem;
      height: 1rem;
      padding: 0;
      border: none;
      border-radius: 50px;
      background-color: #8e9deb;
      text-indent: -9999px;
      height: 8px;
    }

    .slick-dots li.slick-active button {
      background-color: white;
      width: 16px;
    }
    .slick-dots li.slick-active {
      width: 16px;
    }
  }
  &__card-work {
    padding: 1.5rem;
    border-radius: 24px;
  }
  &__rounded {
    &-left {
      border-top-left-radius: 32px;
      border-bottom-left-radius: 32px;

      @media screen and(max-width: $br-sm) {
        border-top-right-radius: 32px;
        border-bottom-left-radius: 0;
      }
    }
    &-right {
      border-top-right-radius: 32px;
      border-bottom-right-radius: 32px;

      @media screen and(max-width: $br-sm) {
        border-bottom-left-radius: 32px;
        border-top-right-radius: 0;
      }
    }
  }
  &__card-featured {
    padding: 1.5rem 0.5rem;
  }
  &__tab-grid {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and(max-width: $br-md) {
      flex-wrap: wrap;
    }

    @media screen and(max-width: $br-sm) {
      flex-wrap: wrap;

      .items {
        margin-bottom: 1rem;
        max-width: 55%;
      }

      .sm-none {
        display: none;
      }
    }
    @media screen and(max-width: $br-xs) {
      .items {
        margin-bottom: 1rem;
        max-width: 40%;
      }
    }
    @media screen and(max-width: $br-xx) {
      .items {
        max-width: 100%;
      }

      .line {
        display: none;
      }
    }

    .line {
      width: 1px;
      height: 46px;
      background-color: #e9ebed;
      margin: 0 16px;
      flex-shrink: 0;

      &.none {
        background-color: transparent;
        display: none;
      }
    }
    > .btn {
      @media screen and(max-width: $br-mm) {
        width: 100%;
      }
    }
    > .field__input {
      border: 1px solid #e9ebed;
      border-radius: 24px;
      padding: 0.7rem 1rem;

      @media screen and(max-width: $br-mm) {
        width: 100%;
        max-width: 100%;
      }

      .icon {
        object-fit: contain;
      }
      div {
        input {
          background-color: transparent;
          border: none;
          outline-style: none;
          box-shadow: none;
          width: 170px;

          @media screen and(max-width: 1024px) {
            width: 130px;
          }
          @media screen and(max-width: $br-md) {
            width: 100%;
          }

          &:hover {
            background-color: transparent;
            border: none;
            outline-style: none;
            box-shadow: none;
          }
          &:focus {
            background-color: transparent;
            border: none;
            outline-style: none;
            box-shadow: none;
          }
          &:active {
            background-color: transparent;
            border: none;
            outline-style: none;
            box-shadow: none;
          }
        }
      }
    }
  }
  &__tab-filter-ngull {
    width: 986px;
    padding: 1rem;
    background: linear-gradient(
      265.67deg,
      rgba(238, 241, 251, 0.56) -3.26%,
      #eef1fb 50.21%
    );
    box-shadow: 32px 64px 64px rgba(158, 150, 150, 0.08);
    backdrop-filter: blur(10px);
    border-radius: 24px;
    position: relative;
    z-index: 2;

    @media screen and(max-width: 1024px) {
      width: 850px;
    }
    @media screen and(max-width: $br-md) {
      width: 100%;
    }

    .tab-content {
      padding: 0.5rem 1rem;
    }
    .nav {
      .nav-item {
        a {
          background-color: transparent !important;
          position: relative;
          padding: 0.5rem 0;
          margin: 0 1rem;
          transition: 0.5s;
          &::after {
            content: "";
            position: absolute;
            width: 0;
            left: 0;
            bottom: 0;
            height: 1px;
            background-color: color("black");
            opacity: 0;
            transition: 0.5s;
          }
          &.active {
            color: color("black") !important;
            &::after {
              opacity: 1;
              width: 100%;
            }
          }
        }
      }
    }
  }
}

.text {
}

.images {
  &__review{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 24px;
    max-height: 344px;
  }
  &__sosmed {
    width: 32px;
    height: 32px;
    object-fit: cover;
    border-radius: 50%;
  }
  &__user-profile {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 50%;
    margin: 0 auto;
    border: 1.5px solid #ffffff;
  }
  &__2 {
    position: absolute;
    top: 15rem;
    right: 0;
    width: 42rem;
  }
  &__1 {
    position: absolute;
    bottom: -20rem;
    right: 0;
    width: 39rem;
  }
  &__community {
    height: 100%;
    width: 100%;
    border-radius: 24px;
    object-fit: cover;
  }
  &__detail-blog {
    width: 100%;
    height: 400px;
    border-radius: 16px;
    object-fit: cover;

    @media screen and(max-width: $br-md) {
      height: 250px;
    }
  }
  &__wrap-work {
    width: 100%;
    height: 100%;
    border-radius: 24px;
    object-fit: cover;
  }
  &__right-client {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 50%;

    @media screen and(max-width: $br-md) {
      padding-bottom: 0;
      position: initial;
      bottom: initial;
      right: initial;
      width: initial;
      margin-right: -4rem;
    }
    @media screen and(max-width: $br-sm) {
      margin-right: -2rem;
    }
  }
  &__ornamen-client {
    position: absolute;
    top: 0;
    right: 0;
    object-fit: cover;
  }
  &__head-top {
    position: absolute;
    top: 0;
    left: 61vw;
  }
}

.btn {
  &__lg-sign {
    @media screen and(max-width:1024px) {
      padding: 17px 32px;
    }
  }
  &__upload {
    padding: 12px 45px;
  }
  &__search {
    padding: 13px 35px;
  }
  &__auth {
    padding: 17px 45px;
    background-color: #e5e5e7;
    color: #a2a1a7;
    border-radius: 50px;
    display: inline-block;
    transition: 0.5s;
    box-shadow: none;

    &:hover {
      color: white;
      background-color: color("blue");
      box-shadow: 0 15px 30px transparentize(color("blue"), 0.5);
    }
  }
  &__auth-account {
    padding: 16px 10px;
    width: 100%;
    border-radius: 50px;
    border: 1px solid #e9ebed;
    display: inline-block;
  }
}
.path {
}

.list {
}

.line {
}

.pt {
  &__100 {
    padding-top: 100px;
    @media screen and(max-width: $br-md) {
      padding-top: 61px;
    }
  }
}

.p {
  &__4 {
    padding: 4rem;
  }
}

.px {
  &-xx-10 {
    @media screen and(max-width: $br-xx) {
      padding: 0 10px;
    }
  }
}

.mt {
  &__min {
    &-17 {
      margin-top: -17rem;

      @media screen and(max-width: $br-xs) {
        margin-top: -14rem;
      }
      @media screen and(max-width: $br-xxs) {
        margin-top: -13rem;
      }
    }
  }
  &__4 {
    margin-top: 4rem;
    &.sm-0 {
      @media screen and(max-width: $br-sm) {
        margin-top: 0rem;
      }
    }
  }
  &__100 {
    margin-top: 100px;
  }
  &__5 {
    margin-top: 5rem;

    &.md-0 {
      @media screen and(max-width: $br-md) {
        margin-top: 0rem;
      }
    }
  }
  &__10 {
    margin-top: 10rem;

    &.lg-0 {
      @media screen and(max-width: $br-lg) {
        margin-top: 0rem;
      }
    }
  }
  &__18 {
    margin-top: 18rem;

    &.md-0 {
      @media screen and(max-width: $br-md) {
        margin-top: 0rem;
      }
    }
  }
}

.hr {
  &__footer {
    border-top: 1px solid #322f37;
  }
  &__line-contact {
    border-top: 1px solid #d9d9d9;
  }
}

.mb {
  &__min {
    &-18 {
      margin-bottom: -18rem;
    }
  }
  &__4 {
    margin-bottom: 4rem;
  }
  &__5 {
    margin-bottom: 5rem;
  }

  &__md-2 {
    @media screen and(max-width: $br-md) {
      margin-bottom: 1.5rem;
    }
  }
}

// typography

.text {
  &__163 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 100px !important;
      }
    }

    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 90px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 65px !important;
      }
    }

    &-xs {
      @media screen and(max-width: $br-xs) {
        font-size: 50px !important;
      }
    }
  }

  &__100 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 80px !important;
      }
    }

    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 70px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 60px !important;
      }
    }

    &-mm {
      @media screen and(max-width: $br-mm) {
        font-size: 50px !important;
      }
    }

    &-xx {
      @media screen and(max-width: $br-xx) {
        font-size: 45px !important;
      }
    }

    &-xxs {
      @media screen and(max-width: $br-xxs) {
        font-size: 40px !important;
      }
    }
  }
  &__150 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 120px !important;
      }
    }

    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 100px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 60px !important;
      }
    }

    &-mm {
      @media screen and(max-width: $br-mm) {
        font-size: 50px !important;
      }
    }

    &-xx {
      @media screen and(max-width: $br-xx) {
        font-size: 45px !important;
      }
    }

    &-xxs {
      @media screen and(max-width: $br-xxs) {
        font-size: 40px !important;
      }
    }
  }

  &__200 {
    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 150px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 125px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-mm) {
        font-size: 100px !important;
      }
    }

    &-xx {
      @media screen and(max-width: $br-xx) {
        font-size: 85px !important;
      }
    }

    &-xxs {
      @media screen and(max-width: $br-xxs) {
        font-size: 75px !important;
      }
    }
  }

  &__77 {
    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 50px !important;
      }
    }
  }

  &__80 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 55px !important;
      }
    }

    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 65px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 50px !important;
      }
    }

    &-xs {
      @media screen and(max-width: $br-xs) {
        font-size: 40px !important;
      }
    }
  }

  &__70 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 50px !important;
      }
    }

    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 40px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 40px !important;
      }
    }

    &-xs {
      @media screen and(max-width: $br-xs) {
        font-size: 40px !important;
      }
    }

    &-xxs {
      @media screen and(max-width: $br-xxs) {
        font-size: 35px !important;
      }
    }
  }

  &__65 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 45px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 35px !important;
      }
    }
    &-xxs {
      @media screen and(max-width: $br-xxs) {
        font-size: 28px !important;
      }
    }
  }

  &__60 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 50px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 35px !important;
      }
    }

    &-xxs {
      @media screen and(max-width: $br-xxs) {
        font-size: 30px !important;
      }
    }
  }

  &__50 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 40px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 35px !important;
      }
    }
    &-xs {
      @media screen and(max-width: $br-xs) {
        font-size: 25px !important;
      }
    }
  }

  &__25 {
    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 20px !important;
      }
    }
  }

  &__45 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 30px !important;
      }
    }

    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 24px !important;
      }
    }

    &-mm {
      @media screen and(max-width: $br-mm) {
        font-size: 16px !important;
      }
    }
  }

  &__40 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 30px !important;
      }
    }

    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 28px !important;
      }
    }

    &-mm {
      @media screen and(max-width: $br-mm) {
        font-size: 24px !important;
      }
    }
  }

  &__30 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 25px !important;
      }
    }

    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 25px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 25px !important;
      }
    }

    &-xx {
      @media screen and(max-width: $br-xx) {
        font-size: 20px !important;
      }
    }
  }

  &__32 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 25px !important;
      }
    }

    &-xs {
      @media screen and(max-width: $br-xs) {
        font-size: 18px !important;
      }
    }
  }

  &__36 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 28px !important;
      }
    }
  }

  &__28 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 20px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 20px !important;
      }
    }
  }

  &__35 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 28px !important;
      }
    }

    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 20px !important;
      }
    }

    &-xs {
      @media screen and(max-width: $br-xs) {
        font-size: 18px !important;
      }
    }
  }

  &__38 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 30px !important;
      }
    }
  }

  &__90 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 80px !important;
      }
    }

    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 70px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 55px !important;
      }
    }

    &-xs {
      @media screen and(max-width: $br-xs) {
        font-size: 40px !important;
      }
    }

    &-xxs {
      @media screen and(max-width: $br-xs) {
        font-size: 38px !important;
      }
    }
  }

  &__33 {
    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 25px !important;
      }
    }

    &-xx {
      @media screen and(max-width: $br-xx) {
        font-size: 18px !important;
      }
    }
  }

  &__18 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 16px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 16px !important;
      }
    }

    &-xs {
      @media screen and(max-width: $br-xs) {
        font-size: 14px !important;
      }
    }

    &-xxs {
      @media screen and(max-width: $br-xxs) {
        font-size: 12px !important;
      }
    }
  }

  &__16 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 14px !important;
      }

      &-12 {
        @media screen and(max-width: 1024px) {
          font-size: 12px !important;
        }
      }

      &-10 {
        @media screen and(max-width: 1024px) {
          font-size: 10px !important;
        }
      }
    }

    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 12px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 12px !important;
      }
    }

    &-mm {
      @media screen and(max-width: $br-mm) {
        font-size: 10px !important;
      }

      &-12 {
        @media screen and(max-width: $br-mm) {
          font-size: 12px !important;
        }
      }
    }

    &-xx {
      @media screen and(max-width: $br-xx) {
        font-size: 10px !important;
      }
    }
  }

  &__20 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 16px !important;
      }
    }
    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 14px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 12px !important;
      }
    }
  }

  &__120 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 90px !important;
      }
    }

    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 69px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 75px !important;
      }
    }

    &-xxs {
      @media screen and(max-width: $br-xxs) {
        font-size: 55px !important;
      }
    }
  }

  &__26 {
    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 20px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 14px !important;
      }
    }
  }

  &__22 {
    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 16px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 14px !important;
      }
    }
  }

  &__12 {
    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 10px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 10px !important;
      }

      &-8 {
        @media screen and(max-width: $br-sm) {
          font-size: 8px !important;
        }
      }
    }

    &-xs {
      @media screen and(max-width: $br-xs) {
        font-size: 8px !important;
      }

      &-10 {
        @media screen and(max-width: $br-xs) {
          font-size: 10px !important;
        }
      }
    }

    &-mm {
      @media screen and(max-width: $br-mm) {
        font-size: 8px !important;
      }

      &-10 {
        @media screen and(max-width: $br-mm) {
          font-size: 10px !important;
        }
      }
    }
  }

  &__14 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 12px !important;
      }

      &-10 {
        @media screen and(max-width: 1024px) {
          font-size: 10px !important;
        }
      }
    }

    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 12px !important;
      }
    }

    &-sm {
      &-8 {
        @media screen and(max-width: $br-sm) {
          font-size: 8px !important;
        }
      }
    }

    &-mm {
      @media screen and(max-width: $br-mm) {
        font-size: 9px !important;
      }

      &-10 {
        @media screen and(max-width: $br-mm) {
          font-size: 10px !important;
        }
      }
    }

    &-xx {
      @media screen and(max-width: $br-xx) {
        font-size: 10px !important;
      }

      &-8 {
        @media screen and(max-width: $br-xx) {
          font-size: 8px !important;
        }
      }
    }
  }

  &__27 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 18px !important;
      }
    }
  }

  &__24 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 18px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 16px !important;
      }
    }
    &-xs {
      @media screen and(max-width: $br-xs) {
        font-size: 14px !important;
      }
    }
  }

  &__25 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 20px !important;
      }
    }
  }

  &__36 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 25px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 20px !important;
      }
    }
  }

  &__58 {
    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 45px !important;
      }
    }
  }

  &__48 {
    &-1024 {
      @media screen and(max-width: 1024px) {
        font-size: 40px !important;
      }
    }

    &-md {
      @media screen and(max-width: $br-md) {
        font-size: 35px !important;
      }
    }

    &-sm {
      @media screen and(max-width: $br-sm) {
        font-size: 30px !important;
      }
    }
    &-xx {
      @media screen and(max-width: $br-xx) {
        font-size: 24px !important;
      }
    }

    &-xxs {
      @media screen and(max-width: $br-xxs) {
        font-size: 18px !important;
      }
    }
  }
}

.wrap {
  &__flex {
    &-1024 {
      &-100 {
        @media screen and(max-width: 1024px) {
          flex-flow: wrap;
        }
        > .grid {
          @media screen and(max-width: 1024px) {
            flex: 0 0 100% !important;
            max-width: 100% !important;
          }
        }
      }

      &-50 {
        @media screen and(max-width: 1024px) {
          flex-flow: wrap;
        }
        > .grid {
          @media screen and(max-width: 1024px) {
            flex: 0 0 45%;
            max-width: 45%;
          }
        }
      }

      &-90 {
        &-3 {
          @media screen and(max-width: 1024px) {
            flex-flow: wrap;
          }
          > .grid {
            @media screen and(max-width: 1024px) {
              flex: 0 0 calc(90% / 3);
              max-width: calc(90% / 3);
            }
          }
        }
      }

      &-auto {
        @media screen and(max-width: 1024px) {
          flex-flow: wrap;
        }
        > .grid {
          @media screen and(max-width: 1024px) {
            flex: 0 0 auto !important;
            max-width: inherit !important;
          }
        }
      }
    }

    &-md {
      &-100 {
        @media screen and(max-width: $br-md) {
          flex-flow: wrap;
        }
        > .grid {
          @media screen and(max-width: $br-md) {
            flex: 0 0 100%;
            max-width: 100%;
          }
        }
      }

      &-50 {
        @media screen and(max-width: $br-md) {
          flex-flow: wrap;
        }
        > .grid {
          @media screen and(max-width: $br-md) {
            flex: 0 0 45%;
            max-width: 45%;
          }
        }
      }

      &-auto {
        @media screen and(max-width: $br-md) {
          flex-flow: wrap;
        }
        > .grid {
          @media screen and(max-width: $br-md) {
            flex: 0 0 auto !important;
            max-width: inherit !important;
          }
        }
      }
    }

    &-sm {
      &-100 {
        @media screen and(max-width: $br-sm) {
          flex-flow: wrap;
        }
        > .grid {
          @media screen and(max-width: $br-sm) {
            flex: 0 0 100%;
            max-width: 100%;
          }
        }
      }

      &-50 {
        @media screen and(max-width: $br-sm) {
          flex-flow: wrap;
        }
        > .grid {
          @media screen and(max-width: $br-sm) {
            flex: 0 0 45%;
            max-width: 45%;
          }
        }
      }
    }

    &-xs {
      &-100 {
        @media screen and(max-width: $br-xs) {
          flex-flow: wrap;
        }
        > .grid {
          @media screen and(max-width: $br-xs) {
            flex: 0 0 100%;
            max-width: 100%;
          }
        }
      }

      &-50 {
        @media screen and(max-width: $br-sm) {
          flex-flow: wrap;
        }
        > .grid {
          @media screen and(max-width: $br-sm) {
            flex: 0 0 45%;
            max-width: 45%;
          }
        }
      }
    }

    &-mm {
      &-100 {
        @media screen and(max-width: $br-mm) {
          flex-flow: wrap;
        }
        > .grid {
          @media screen and(max-width: $br-mm) {
            flex: 0 0 100%;
            max-width: 100%;
          }
        }
      }

      &-50 {
        @media screen and(max-width: $br-sm) {
          flex-flow: wrap;
        }
        > .grid {
          @media screen and(max-width: $br-sm) {
            flex: 0 0 45%;
            max-width: 45%;
          }
        }
      }

      &-4 {
        @media screen and(max-width: $br-mm) {
          flex-flow: wrap;
        }
        > .grid {
          @media screen and(max-width: $br-mm) {
            flex: 0 0 calc(100% / 6);
            max-width: calc(100% / 6);
          }
        }
      }
    }
  }
}

// animated
@keyframes move1 {
  0% {
    transform: translate(0);
  }
  25% {
    transform: translate(13.5rem, 14.5rem);
  }
  75% {
    transform: translate(27rem, 0rem);
  }
  100% {
    transform: translate(0);
  }
}
@keyframes move12 {
  0% {
    transform: translate(0);
  }
  25% {
    transform: translate(9.8rem, 13rem);
  }
  75% {
    transform: translate(19.7rem, 0rem);
  }
  100% {
    transform: translate(0);
  }
}
@keyframes move2 {
  0% {
    transform: translate(0);
  }
  25% {
    transform: translate(-27rem, 0rem);
  }
  75% {
    transform: translate(-13.4rem, 14.5rem);
  }
  100% {
    transform: translate(0);
  }
}
@keyframes move22 {
  0% {
    transform: translate(0);
  }
  25% {
    transform: translate(-19.5rem, 0rem);
  }
  75% {
    transform: translate(-10rem, 13rem);
  }
  100% {
    transform: translate(0);
  }
}
@keyframes move3 {
  0% {
    transform: translate(0);
  }
  25% {
    transform: translate(13.5rem, -14.5rem);
  }
  75% {
    transform: translate(-13.5rem, -14.5rem);
  }
  100% {
    transform: translate(0);
  }
}
@keyframes move32 {
  0% {
    transform: translate(0);
  }
  25% {
    transform: translate(10rem, -13rem);
  }
  75% {
    transform: translate(-10rem, -13rem);
  }
  100% {
    transform: translate(0);
  }
}
