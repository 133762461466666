// Fonts
@import url('https://api.fontshare.com/v2/css?f[]=general-sans@200,500,300,600,400,700&display=swap');

// Fonts
.General {
  font-family: 'General Sans', sans-serif;
}


h1 {
  font-size: 70px;
}

h2 {
  font-size: 50px;
  @media screen and(max-width: $br-md) {
    font-size: 35px;
  }
}

h3 {
  font-size: 40px;
  @media screen and(max-width: $br-md) {
    font-size: 25px;
  }
}

h4 {
  font-size: 30px;
  @media screen and(max-width: $br-md) {
    font-size: 20px;
  }
}

h5 {
  font-size: 24px;
  @media screen and(max-width: $br-md) {
    font-size: 18px;
  }
}

h6 {
  font-size: 18px;
  @media screen and(max-width: $br-md) {
    font-size: 14px;
  }
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 900;
}

.thin {
  font-weight: 100 !important;
}

.extra-light {
  font-weight: 200 !important;
}

.light {
  font-weight: 300 !important;
}

.regular {
  font-weight: 400 !important;
}

.medium {
  font-weight: 500 !important;
}

.semi-bold {
  font-weight: 600 !important;
}

.bold {
  font-weight: 700 !important;
}

.extra-bold {
  font-weight: 800 !important;
}

.black {
  font-weight: 900 !important;
}

.normal {
  font-weight: normal !important;
}

.uppercase__font {
  text-transform: uppercase;
}
.capitalize__font {
  text-transform: capitalize;
}

.justify {
  text-align: justify;
}

.underline {
  text-decoration: underline;
}

@font-face {
  font-family: circular;
  src: url("../fonts/CircularStd-Book.ttf");
}

.circular {
  font-family: circular;
}
